import React from 'react';
import Badges from '../../Badges';
import TruspilotWidget from '../../TruspilotWidget';
import downloadAppBackground from '../../../assets/images/illustrations/download-app-bg.png';
import { MainDescription, SecondaryTitle } from '../../Typography';
import CustomHeadingSection from '../CustomHeadingSection';
import SectionWrapper from '../SectionWrapper';
import useWindow from '../../../hooks/useWindow';

function DownloadAppSection() {
  const { isMobile } = useWindow();

  return (
    <div className="download-section">
      <SectionWrapper>
        <CustomHeadingSection
          leftContent={
            <div className="download-section__container-left">
              {isMobile ? (
                <>
                  <div className="download-section__description-container">
                    <SecondaryTitle>Download Sprive</SecondaryTitle>
                    <MainDescription>
                      Sprive supports 12 of the largest lenders in the UK.
                    </MainDescription>
                    <MainDescription>
                      <span>It’s completely free.</span>
                    </MainDescription>
                  </div>
                </>
              ) : (
                <>
                  <SecondaryTitle>Download Sprive Today</SecondaryTitle>
                  <MainDescription>
                    Sprive supports 12 of the largest lenders in the UK. It’s completely free.
                  </MainDescription>
                </>
              )}
              <TruspilotWidget />
            </div>
          }
          rightContent={
            <div className="download-section__container-right">
              <Badges />
            </div>
          }
        />
        <img className="download-section__bg-image" src={downloadAppBackground} />
      </SectionWrapper>
    </div>
  );
}

export default DownloadAppSection;
