import React, { useCallback } from 'react';
import SectionWrapper from './CommonSections/SectionWrapper';
import Heading from './Heading';
import TextDropDown from './TextDropDown';

function FAQSection({ FAQItems, title, subtitle }) {
  const genarateFAQItems = useCallback(() => {
    const components = [];

    FAQItems.forEach((item, index) => {
      components.push(
        <TextDropDown key={index} title={item.title} description={item.description} animated />
      );
    });

    return components;
  }, [FAQItems]);

  return (
    <div style={{ background: '#FFF3F3' }}>
      <SectionWrapper>
        <Heading title={title} subtitle={subtitle} />
        <div className="FAQ-section__items-container">{genarateFAQItems()}</div>
      </SectionWrapper>
    </div>
  );
}

export default FAQSection;
