import React from 'react';
import { useMemo } from 'react';
import Button from '../../Button';
import CustomImage from '../../Image/Image';
import TruspilotWidget from '../../TruspilotWidget';
import { MainDescription, MainTitle } from '../../Typography';

function HeaderLeftContent({title, description, buttonText, handleClick, image, imageReversed, noPadding, useWidget, hashUrlOnClick}) {
  const containerClassName = useMemo(() => {
    const basicClassName = "remortgage-page__text-container"

    return noPadding ? `${basicClassName} ${basicClassName}__no-padding` : basicClassName
  }, [])

  return (
    <div className={containerClassName}>
      <MainTitle>
        {title}
      </MainTitle>
      <div className="remortgage-page__description-container">
        <MainDescription>
          {description}
        </MainDescription>
      </div>
      {(image && !imageReversed) && (
        <div className="background-image__container">
            <CustomImage 
                className="background-image"
                src={image}
            />
        </div>
      )}
      {buttonText && (
        <>
            <div className="remortgage-page__button-container">
            {hashUrlOnClick ? (
                <a href={hashUrlOnClick}>
                    <Button buttonText={buttonText} height="68px" handleClick={handleClick}/>
                </a>
            ) : (
                <Button buttonText={buttonText} height="68px" handleClick={handleClick}/>
            )}
            </div>
            <TruspilotWidget customWidget={!useWidget}/>
        </>      
      )}
      {(image && imageReversed) && (
        <div className="background-image__container">
            <CustomImage 
                className="background-image"
                src={image}
            />
        </div>
      )}
    </div>
  );
}

export default HeaderLeftContent;
