import React, { useEffect } from 'react'
import { graphql, Link, navigate } from 'gatsby';
import { isValid } from "postcode";
import SEO from '../components/SEO';
import Img from 'gatsby-image';
import { useMemo } from 'react';
import SliderSection from '../components/CommonSections/SliderSection';
import imageStepsIcon from '../assets/images/icons/other/template-page-steps-icon.png'
import FAQSection from '../components/FAQSection';
import DownloadAppSection from '../components/CommonSections/DownloadAppSection';
import SectionWrapper from '../components/CommonSections/SectionWrapper';
import CustomHeadingSection from '../components/CommonSections/CustomHeadingSection';
import HeaderLeftContent from '../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import { SecondaryDescription, SecondaryTitle } from '../components/Typography';
import Heading from '../components/Heading';
import Button from '../components/Button';
import { useForm } from 'react-hook-form';
import RegisteredInput from '../components/CommonSections/formElements/RegisteredInput';
import InputWithValidation from '../components/CommonSections/formElements/InputWithValidation';
import useLenders from '../hooks/useLenders';
import Dropdown from '../components/CommonSections/formElements/Dropdown';
import { useState } from 'react';
import { useCallback } from 'react';
import { SPRIVE_API_BASE_URL, SPRIVE_API_KEY } from '../constants';
import useWindow from '../hooks/useWindow';
import imageTestimonialsIndi1x from '../assets/images/illustrations/indi-testimonials1x.png'
import imageTestimonialsIndi2x from '../assets/images/illustrations/indi-testimonials2x.png'
import imageTestimonialsJenny1x from '../assets/images/illustrations/jenny-testimonials1x.png'
import imageTestimonialsJenny2x from '../assets/images/illustrations/jenny-testimonials2x.png'
import imageTestimonialsBrijesh1x from '../assets/images/illustrations/brijesh-testimonials1x.png'
import imageTestimonialsBrijesh2x from '../assets/images/illustrations/brijesh-testimonials2x.png'
import imageTestimonialsBella1x from '../assets/images/illustrations/bella-testimonials1x.png'
import imageTestimonialsBella2x from '../assets/images/illustrations/bella-testimonials2x.png'
import ModalsFlow from '../components/Modals/ModalsFlow';
import BasicModal from '../components/Modals/BasicModal';
import DownloadBonusModal from '../components/Modals/DownloadBonusModal';
import useSSR from '../hooks/useSSR';
import useSliderHeight from '../hooks/useSliderHeight';
import { useContext } from 'react';
import { LayoutContext } from '../components/Layout';

const FAQItems = [
  {
    title: 'How does Sprive help with remortgaging?',
    description: (
      <>
        If you’re using the Sprive app to mortgage overpayments, then you’ll unlock the ability for our 
        smart technology to compare your mortgage against the market every day and see live personalised 
        deals within the app. At the right time, we’ll connect you to a mortgage expert who will help you 
        secure the right deal for you for free.  It’s a fast way to switch as we already know a lot about 
        you and your mortgage. By switching via the app flow, we’ll seamlessly help you make mortgage 
        overpayments on your new mortgage and be able to automatically take into account any early repayment 
        charges associated with your new mortgage
        <br />
        <br />
        If you’re not a user of the app, we can still help you. Simply book an appointment with 
        an expert via our website.

      </>
    ),
  },
  {
    title: 'What is Remortgaging?',
    description: (
      <>
        Remortgaging is the process of switching your current mortgage to a new lender or 
        negotiating a new deal with your current lender. The main reasons for remortgaging are to 
        save money on your mortgage payments, release equity from your property, or change the
        terms of your mortgage agreement. Read our <Link to="https://sprive.com/blog/20-reasons-to-remortgage-with-sprive/">blog</Link> here to learn more on the benefits of remortgaging with us as a Sprive app user.
      </>
    ),
  },
  {
    title: 'What is the difference between mortgage and remortgage?',
    description: (
      <>
        A mortgage is a loan you take out to buy a property, while a remortgage is when you switch 
        your existing mortgage to a new one. When you first take out a mortgage, it is typically for a 
        fixed term, usually between 2 and 5 years. After this initial period, you can either stay on the 
        same mortgage deal or switch to a new deal by remortgaging.
      </>
    ),
  },
  {
    title: 'Is it cheaper to remortgage or get a new mortgage?',
    description: (
      <>
        Remortgaging can be cheaper than getting a new mortgage, depending on the interest rates 
        and fees involved. When deciding between remortgaging or getting a new mortgage, 
        consider the following factors:
        <br />
        <br />
        <ul>
          <li>
            Compare the interest rates and fees involved to determine which option is cheaper
          </li>
          <li>
            Consider the length of the mortgage term
          </li>
          <li>
            Look at any penalties for early repayment
          </li>
          <li>
            Think about your long-term financial goals
          </li>
        </ul>
        <br />
        Remember to shop around and compare deals to find the best option for your individual circumstances.
      </>
    ),
  },
  {
    title: 'Why should I remortgage?',
    description: (
      <>
        People remortgage for various reasons, including securing better interest rates across the 
        mortgage market, accessing equity to fund home improvements, or switching to a different 
        type of mortgage (for example, away from a fixed rate deal to one with the lender's standard 
        variable rate). Additionally, it can be a way to consolidate debt into a single payment. For
        more information, read our blog <Link to="https://sprive.com/blog/when-should-you-switch-mortgage/">here</Link>.
      </>
    ),
  },
  {
    title: 'What are the key factors I need to consider when I remortgage?',
    description: (
      <>
        When remortgaging, consider factors like fees, interest rates, mortgage term length, and any 
        penalties for early repayment. It's also important to think about your long-term financial goals 
        and whether remortgaging aligns with them and the purpose behind it. 
        <br />
        <br />
        For example, if you’re considering releasing equity from your home to finance a second 
        property, it's essential to evaluate the investment potential of the money you release. 
        Additionally, using the equity for home improvements could increase your property's value 
        and yield a better return on investment.
        <br />
        <br />
        When releasing equity, it's crucial to consider the impact on your overall financial situation, 
        including the potential for higher mortgage payments and longer repayment terms. Before 
        deciding to remortgage, it's advisable to speak to an independent financial advisor to ensure 
        that you're making an informed decision.
      </>
    ),
  },
  {
    title: 'What are the expenses associated with a remortgage?',
    description: (
      <>
        When you decide to remortgage your property, there are several fees associated with the 
        process. Here are some of the common fees you may encounter:
        <br />
        <br />
        <ol>
          <li>
            Valuation fee: This is the cost of having your property valued by a surveyor to 
            determine its current market value. 
          </li>
          <li>
            Arrangement fee: This is the fee charged by your lender for setting up your new 
            mortgage. It can be a flat fee or a percentage of your loan amount.
          </li>
          <li>
            Legal fees: You will need to hire a solicitor or conveyancer to handle the legal
            aspects of your remortgage.
          </li>
          <li>
            Early repayment charges: If you are remortgaging before the end of your current 
            mortgage term, you may be charged an early repayment fee.
          </li>
          <li>
            Exit fee: Your current lender may charge you an exit fee for leaving their mortgage
          </li>
          <li>
            Broker fee: If you use a mortgage broker to find your new mortgage deal, they may 
            charge a fee for their services.  
          </li>
        </ol>
        <br />
        There may be additional costs involved and that’s why it's important to factor them into your 
        budget. For more information, read our blog <Link to="https://sprive.com/blog/remortgaging-costs-understanding-the-expenses-involved/">here</Link>.
      </>
    ),
  },
  {
    title: 'Am I eligible for a remortgage?',
    description: (
      <>
        You’re typically eligible to remortgage 6 months before your existing deal expires.  
        Once your deal expires, your interest rate interest rate will revert to the Standard 
        Variable Rate (SVR) of the current mortgage lender.  If you switch within your deal 
        period, you’re likely be charged an early repayment charge.
        <br />
        <br />
        When considering a remortgage, your eligibility will depend on factors such as your credit 
        score, income, and equity in your home. Lenders will also look at your existing mortgage and 
        debts to ensure you can manage repayments. Eligibility criteria may vary among lenders, so 
        it's important to compare offers to find the best deal for your situation.
      </>
    ),
  },
  {
    title: 'Should I remortgage with my current lender or a new mortgage lender?',
    description: (
      <>
        When it comes to deciding whether to remortgage with your current lender or 
        switch to a new mortgage lender, there are benefits to both. At Sprive, we have 
        access to mortgage experts who can help you with both. The benefits of sticking 
        with your existing lender include:
        <br />
        <br />
        <ul>
          <li>
            <span>Interest Rates:</span> If you have an established relationship with your lender, they may 
            offer you a more competitive interest rate than a new lender would.
          </li>
          <li>
            <span>Fees & Charges:</span> If you have a good payment history and a strong credit score, 
            you may be able to negotiate with your current lender to waive or reduce certain fees, 
            such as arrangement or valuation fees.
          </li>
          <li>
            <span>Simplified Application Process:</span> You may be able to avoid a credit check or a 
            full affordability assessment, as your lender already has access to your payment 
            history and financial information.
          </li>
        </ul>
        <br />
        On the other hand, benefits of choosing a new lender include:
        <br />
        <br />
        <ul>
          <li>
            <span>Interest Rates:</span> You may find better deals and potentially lower interest rates 
            if you shop across multiple lenders to ensure that you're getting the best possible deal.
          </li>
          <li>
            <span>More Variety:</span> If you're looking for a particular type of mortgage product, 
            such as a flexible mortgage or an interest-only mortgage, you may need to look beyond 
            your current lender to browse mortgage deals and find the right product for you.
          </li>
        </ul>
      </>
    ),
  },
  {
    title: 'What are the risks of remortgaging?',
    description: (
      <>
       Remortgaging can have its downsides, including:
       <br />
       <br />
       <ul>
        <li>
          Fees and costs involved: Remortgaging can be more expensive than expected due to fees 
          and costs like arrangement fees, valuation fees, legal fees, and exit fees.
        </li>
        <li>
          Longer repayment term: Remortgaging to release equity or consolidate debt may extend 
          the repayment term, resulting in paying more interest over the life of the mortgage.
        </li>
        <li>
          Risk of negative equity: If the value of your property decreases, you may end up in 
          negative equity, making it difficult to remortgage or sell your property.
        </li>
        <li>
          Impact on credit score: Remortgaging can affect your credit score, making it 
          harder to obtain credit in the future.
        </li>
        <li>
          Risk of repossession: If you use your property as security for the loan and can't 
          keep up with repayments, you could be at risk of losing your home.
        </li>
       </ul>
       <br />
       It's important to consider the potential downsides and assess whether remortgaging is the best option for your financial situation.
      </>
    ),
  },
  {
    title: 'Do I pay less when I remortgage?',
    description: (
      <>
        Remortgaging can potentially result in lower monthly payments or a lower interest rate, 
        but it depends on the specific deal you can secure. 
        <br />
        <br />
        You may be able to save money by remortgaging to a new fixed or variable rate deal at a 
        lower interest rate, but it's important to compare offers and consider the fees and costs 
        involved depending on whether you’re sticking with your current lender or choosing a new lender. 
        <br />
        <br />
        If you're remortgaging to release equity or consolidate debt, consider the impact of a longer 
        repayment term and increased interest costs over the life of the mortgage.

      </>
    ),
  },
  {
    title: 'How does my credit score affect my remortgage?',
    description: (
      <>
        Your credit score will play a significant role in whether or not you are eligible for a 
        remortgage, as well as the interest rate you are offered. Lenders will use your credit score 
        to assess your risk as a borrower and determine whether or not to offer you a remortgage.
        <br />
        <br />
        However, if you have bad credit, you may find it challenging to remortgage. But it can actually 
        help rebuild your credit score, as it can consolidate debts, provide better credit terms, 
        and lower monthly payments over time. Having bad credit doesn't mean you cannot remortgage, 
        and it's worth exploring your options to see if it could benefit your financial situation.
      </>
    ),
  },
];


const remortgageDropdown = [
    {
        value: "Remortgage",
        id: 0
    },
    {
        value: "Purchase",
        id: 1
    }
]

function TemplatePage({data, location}) {
  const templateData = useMemo(() => data?.allMarkdownRemark?.edges[0]?.node?.frontmatter, [data])
  
  const { setFooterContent } = useContext(LayoutContext)
  const { isDesktop, isMobile } = useWindow()
  const sliderHeight = useSliderHeight("611px", "198px", "234px")
  const { lenders } = useLenders()
  const isSSR = useSSR()
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dropdownError, setDropdownError] = useState([]);
  const [dropdownValue, setDropdownValue] = useState([{
        value: 'Existing Lender',
        id: -1,
    },
    {
        value: "Remortgage",
        id: 0,
    }
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const onSubmit = async (data) => {
    if(!checkDropdown()) {
        return
    }

    setLoading(true)

    postUserQuery(data)
  }

  const checkDropdown = useCallback(() => {
    const allErrorsIndexes = []
    dropdownValue.forEach((item, index) => {
        if (item.id === -1) {
            allErrorsIndexes.push(index)
        }
    })

    if(allErrorsIndexes.length) {
        setDropdownError(allErrorsIndexes)
        return false
    }

    return true;
  }, [dropdownValue]);

  const replaceCommas = useCallback((value) => {
    if(!value?.replace) return value
    return parseInt(value.replace(/,/g, ''));
  });

  const postUserQuery = useCallback(
    async (formData) => {
      let mortgageName = null

      if(process.env.GATSBY_CURRENT_SUBDOMAIN) {
        mortgageName = process.env.GATSBY_CURRENT_SUBDOMAIN
      }
      else {
        const locationPathname = window.location.pathname.split("/")
        mortgageName = locationPathname[locationPathname.length - 1]
      }

      const requestBody = {
        mortgage_name: mortgageName,
        post_code: formData['postcode'],
        mortgage_type: dropdownValue[1].value,
        mortgage_amount: replaceCommas(formData['mortgage_amount']),
        mortgage_length: replaceCommas(formData['mortgage_length']),
        property_value: replaceCommas(formData['property_value']),
        beneficiary_id: dropdownValue[0].id,
        destination: templateData.destination,
        user: {
          email: formData["email"],
          name: formData["full_name"],
          contact_no: formData["contact_number"],
        },
      };

      fetch(SPRIVE_API_BASE_URL + 'product-selector-sub-domain-search-preference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          api_key: SPRIVE_API_KEY,
        },
        body: JSON.stringify(requestBody),
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            localStorage.setItem('uuid', data?.data?.uuid);
            
            localStorage.setItem("mortgageData", JSON.stringify({
              ...requestBody,
              dropdownValue: dropdownValue[0].value
            }))
            setLoading(false)

            const state = {
                useImage: true,
                useDropdown: true,
                customLinks: [
                    {
                        title: 'Home',
                        leadsTo: '/',
                      },
                      {
                        title: 'View Deals',
                        leadsTo: null,
                        isActive: true,
                      },
                ]
            }

            localStorage.setItem("dealsState", JSON.stringify(state))
            navigate('/view-deals');
          }
        });
    },
    [dropdownValue]
  );

  const updateDropdownValue = useCallback((item, index, valueField) => {
    const newState = [...dropdownValue]

    newState[index] = {
        value: item[valueField],
        id: item?.id,
    }

    setDropdownValue(newState)
  }, [dropdownValue])

  const handleBonusClose = useCallback(() => {
    setIsBonusModalOpen(false)
    if(!isSSR) {
        localStorage.setItem("bonusModalState", "close")
    }
  }, [isSSR])

  useEffect(() => {
    if(isSSR) return
    else if(localStorage.getItem("bonusModalState") !== "open") return
      

    setIsBonusModalOpen(true)
  }, [isSSR])

  useEffect(() => {
    if(!lenders) return
  
    lenders.forEach((el) => {
        if(el?.company_name?.toLowerCase()?.replace(" ", "") === templateData.subdomain) {
            updateDropdownValue(el, 0, "company_name")
        }
    })
  }, [lenders])

  useEffect(() => {
    if(!templateData) return

    setFooterContent(templateData.destination)
  }, [templateData])

  const sliderItems = useMemo(() => ([
    {
      image: isMobile ? imageTestimonialsJenny1x : imageTestimonialsJenny2x,
      description: `I recently remortgage with Sprive and I couldn't be happier 
        with the experience. From start to finish, they made the process incredibly 
        simple and efficient, surpassing my expectations in every way.`,
      person: 'Jenny Holden',
      personInfo: 'PR'
    },
    {
      image: isMobile ? imageTestimonialsIndi1x : imageTestimonialsIndi2x,
      description: `Thanks to Sprive I was able to both easily manage repayments, 
        but also find great deals for remortgaging, which Sprive took the hassle out of too.`,
      person: 'Indi Rai',
      personInfo: 'Head of Marketing'
    },
    {
      image: isMobile ? imageTestimonialsBrijesh1x : imageTestimonialsBrijesh2x,
      description: `It’s coming to my time to remortgage. Sprive shows you live deals 
        when you’re ready to remortgage, it's really hassle-free so you don’t need to 
        fill out lots of forms and details because it has all of your mortgage details already.`,
      person: 'Brijesh Pujara',
      personInfo: 'Engineer'
    },
    {
      image: isMobile ? imageTestimonialsBella1x : imageTestimonialsBella2x,
      description: `The app is really user friendly and easy to use. It’s great to 
        be able to chip away at my mortgage and see the impact that’s having on the overall length.`,
      person: 'Bella Melvin',
      personInfo: 'Solicitor'
    }
  ]), [isMobile])

  return (
    <>
        <SEO title={templateData?.seo?.seo_title} description={templateData?.seo?.seo_description} />
        <div className="common-page template-page">
            <SectionWrapper>
                <div style={{marginTop: "20px"}}/>
                <CustomHeadingSection 
                    leftContent={(
                        <div className="template-page__heading-wrapper">
                          <div className="template-page__heading">
                            {isMobile !== undefined && (
                              <Img 
                                  fluid={templateData.main_image?.childImageSharp?.fluid}
                                  imgStyle={{
                                    objectFit: "contain",
                                    objectPosition: isMobile ? "center" : "left"
                                  }}
                                  
                              />
                            )}
                              <HeaderLeftContent 
                                  title="View fixed rate mortgage deals and start comparing rates now"
                                  description={<>
                                    Use our free mortgage service to compare 
                                    {isDesktop ? <br /> : " "}
                                    rates from UK's leading lenders.
                                    </>}
                              />
                          </div>
                        </div>
                    )}
                    rightContent={(
                        <div className="template-page__heading-steps">
                            <div>
                                <SecondaryTitle>How it works</SecondaryTitle>
                                <img src={imageStepsIcon}/>
                            </div>
                            <div className="template-page__heading-step-container">
                                <SecondaryDescription>1. Complete the below simple form</SecondaryDescription>
                                <SecondaryDescription>2. We search the market for the latest mortgage deals</SecondaryDescription>
                                <SecondaryDescription>3. You can see the latest deals and you’ll get connected to an expert advisor for free</SecondaryDescription>
                            </div>
                        </div>
                    )}
                />
                <div className="template-page__form-container">
                    <Heading 
                        title="Tell us about your mortgage"
                    />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="template-page__form">
                            <div className="template-page__form-inputs">
                                <FormInput 
                                    register={register}
                                    name="full_name"
                                    placeholder="Full Name" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true,
                                        
                                    }}
                                />
                                <FormInput 
                                    register={register}
                                    name="contact_number"
                                    placeholder="Contact Number" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true,
                                        pattern: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g,
                                    }}
                                />
                                <FormInput 
                                    register={register}
                                    name="email"
                                    placeholder="Email" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true,
                                        pattern: /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                    }}
                                />
                                <FormInput 
                                    register={register}
                                    name="postcode"
                                    placeholder="Postcode" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true,
                                        validate: (value) => isValid(value)
                                    }}
                                />
                                <Dropdown 
                                    dropdownOptions={remortgageDropdown}
                                    dropdownValue={dropdownValue}
                                    setDropdownValue={(item, index, valueField) => updateDropdownValue(item, index, valueField)}
                                    dropdownError={dropdownError}
                                    setDropdownError={setDropdownError}
                                    withoutIcon
                                    dropdownIndex={1}
                                    valueField="value"
                                />
                                <InputWithValidation 
                                    register={register}
                                    name="mortgage_amount"
                                    placeholder="Mortgage Amount" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true
                                    }}
                                />
                                <InputWithValidation 
                                    register={register}
                                    name="property_value"
                                    placeholder="Property Value" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true
                                    }}
                                />
                                <InputWithValidation 
                                    register={register}
                                    name="mortgage_length"
                                    placeholder="Mortgage Length" 
                                    errors={errors}
                                    validationOptions={{
                                        required: true
                                    }}
                                />
                                <Dropdown 
                                    dropdownOptions={lenders}
                                    dropdownValue={dropdownValue}
                                    setDropdownValue={(item, index, valueField) => updateDropdownValue(item, index, valueField)}
                                    dropdownError={dropdownError}
                                    setDropdownError={setDropdownError}
                                    withoutIcon
                                    dropdownIndex={0}
                                    valueField="company_name"
                                />
                            </div>
                            <SecondaryDescription>
                                By searching you accept our <a href="https://sprive.com/mortgageleads-terms" target="__blank">terms and conditions</a> and agree to our <a href="https://sprive.com/privacy" target="__blank">privacy policy</a>
                            </SecondaryDescription>
                        </div>
                        <Button 
                            buttonText="Get Deals"
                            height="56px"
                            submit
                            handleClick={checkDropdown}
                            loading={loading}
                        />
                    </form>
                </div>
            </SectionWrapper>
            <SliderSection 
              sliderItems={sliderItems}
              customHeight={sliderHeight}
            />
            <FAQSection 
                FAQItems={FAQItems}
                title={
                  <>
                    Have a question about remortgaging?
                    {isDesktop ? <br /> : ' '}
                    Check out our FAQ’s
                  </>
                }
            />
            <DownloadAppSection />
            <ModalsFlow
                isFirstOpened={isContactModalOpen}
                setIsFirstOpened={(state) => setIsContactModalOpen(state)}
            />
            <BasicModal
                isOpen={isBonusModalOpen}
                closeHandler={handleBonusClose}
                isBottom={isMobile}
                style={{
                    content: {
                        height: isMobile ? '540px' : '650px',
                    },
                }}
            > 
            <DownloadBonusModal />
        </BasicModal>
        </div>
    </>
  )
}

const FormInput = ({placeholder, register, errors, name, validationOptions}) => {
    const inputRegister = useMemo(() => register && register(name, validationOptions), [name, validationOptions, errors]);

    return (
        <RegisteredInput
            register={inputRegister}
            name={name}
            errors={errors} 
            validationOptions={validationOptions}
            className="template-page__form-input"
            placeholder={placeholder}
        />
    )
}

export const PageQuery = graphql`
  query TemplatePage($id: String!) {
    allMarkdownRemark(filter: {id: {eq: $id }}) {
      edges {
        node {
          frontmatter {
            lender_name
            subdomain
            destination
            seo {
              seo_description
              seo_title
            }
            main_image {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
              }
          }
        }
      }
    }
  }
`

export default TemplatePage